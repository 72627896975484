import _ from 'lodash';
import { useFeature } from '@growthbook/growthbook-react';
import moment from 'moment';
import type { POLICY_STATUS } from '../../_statics/policy.statics';
import {
  BOUND_STATUS,
  CANCELLED_STATUS,
  CANCEL_TRANSACTION,
  EXPIRED_STATUS,
  PolicyStatus,
  RENEWED_STATUS,
  VOID_STATUS,
  VOID_TRANSACTION_STATUS,
} from '../../_statics/policy.statics';
import {
  BINDER_RESCINDED,
  DECLINED_STATUS,
  DELETED_STATUS,
  INVALID_STATUS,
  LOST_STATUS,
  READY_STATUS,
  REFERRED_STATUS,
  SIGNED_STATUS,
  DRAFT_STATUS,
  TEMPORARY_STATUS,
  IN_FORCE_STATUS,
  ISSUED_STATUS,
  QuoteStatus,
  BINDER_EXPIRED_STATUS,
} from '../../_statics/quote.statics';
import { FEATURE_FLAG_KEYS } from '../../../config/growthbook';

import { ACTIVE_SUBMISSION_STATUSES } from '../../_statics/submission.statics';

export const useAgencyDashboardFeature = () =>
  useFeature(FEATURE_FLAG_KEYS.OPT_IN_NEW_AGENT_EXPERIENCE);

export const earliestPossibleEntityDate = moment('2020-01-01').unix();

export const AGENCY_DASHBOARD_VIEWS = {
  LISTING: 'listing',
  NEW_QUOTE: 'new_quote',
  RENEW_POLICY: 'renew_policy',
  BIND_QUOTE: 'bind_quote',
  ACCOUNT_RISK_LOADER: 'account_risk_loader',
  ACCOUNT_RISK_ERROR: 'account_risk_error',
  ACCOUNT_ALREADY_SIGNED: 'account_already_signed',
} as const;

export type AgencyDashboardView =
  (typeof AGENCY_DASHBOARD_VIEWS)[keyof typeof AGENCY_DASHBOARD_VIEWS];

export const AGENCY_DASHBOARD_LISTING_TABS = {
  QUOTES: 'QUOTES',
  BINDERS: 'BINDERS',
  POLICIES: 'POLICIES',
  ACCOUNTS: 'ACCOUNTS',
  SUBMISSIONS: 'SUBMISSIONS',
} as const;

export type AgencyDashboardListingTab =
  (typeof AGENCY_DASHBOARD_LISTING_TABS)[keyof typeof AGENCY_DASHBOARD_LISTING_TABS];

export const AGENCY_DASHBOARD_SEARCH_TABS = {
  CURRENT_TEAM: 'CURRENT_TEAM',
  ALL_MY_TEAMS: 'ALL_MY_TEAMS',
  MY_AGENCY: 'MY_AGENCY',
} as const;

export type AgencyDashboardSearchTab =
  (typeof AGENCY_DASHBOARD_SEARCH_TABS)[keyof typeof AGENCY_DASHBOARD_SEARCH_TABS];

export const AGENCY_DASHBOARD_ONBOARDING_SCREEN_TITLES = {
  [AGENCY_DASHBOARD_VIEWS.NEW_QUOTE]: 'START A NEW QUOTE',
  [AGENCY_DASHBOARD_VIEWS.RENEW_POLICY]: 'RENEW A POLICY',
  [AGENCY_DASHBOARD_VIEWS.BIND_QUOTE]: 'BIND A QUOTE',
} as const;

export type AgencyDashboardOnboardingScreenTitleKeys =
  keyof typeof AGENCY_DASHBOARD_ONBOARDING_SCREEN_TITLES;

export const BINDER_STATUSES = {
  BOUND: 'BOUND',
  BINDER_EXPIRED: 'BINDER EXPIRED',
  RESCIND: 'RESCIND',
} as const;

export type BinderStatus = keyof typeof BINDER_STATUSES;

export interface QueryParams {
  before: number | string;
  after: number | string;
  page: number | string;
  size: number | string;
  orderBy: string;
  order: string;
  search: string;
  f: any;
  accountId: string;
}

export interface AgencyDashboardQueries {
  ACCOUNTS_QUERY: Partial<QueryParams>;
  QUOTES_QUERY: Partial<QueryParams>;
  BINDERS_QUERY: Partial<QueryParams>;
  POLICIES_QUERY: Partial<QueryParams>;
  SUBMISSIONS_QUERY: Partial<QueryParams>;
}

export const AgencyDashboardQueryKeys = {
  QUOTES: 'agency-dashboard-quotes',
  BINDERS: 'agency-dashboard-binders',
  POLICIES: 'agency-dashboard-policies',
  ACCOUNTS: 'agency-dashboard-accounts',
  SEARCH: 'agency-dashboard-search',
  SUBMISSIONS: 'agency-dashboard-submissions',
} as const;

export const formatPolicyStatus = (status: POLICY_STATUS | string) => {
  return PolicyStatus[status as keyof typeof PolicyStatus]
    ?.split(' ')
    ?.join('_');
};

const getQuotesBaseFilters = () => {
  const invalidStatuses = {
    SIGNED_STATUS,
    DELETED_STATUS,
    VOID_STATUS,
    CANCELLED_STATUS,
    DRAFT_STATUS,
    IN_FORCE_STATUS,
    ISSUED_STATUS,
    TEMPORARY_STATUS,
    BINDER_RESCINDED,
    BINDER_EXPIRED_STATUS,
    BOUND_STATUS,
  };
  return Object.keys(QuoteStatus)
    .filter((status) => !(status in invalidStatuses))
    .map((status) => QuoteStatus[status as keyof typeof QuoteStatus])
    .join(',');
};

export const quotesBaseFilterConfig = {
  f: { agencyStatus: { in: getQuotesBaseFilters() } },
};

export const bindersBaseFilterConfig = {
  f: { status: { in: Object.keys(BINDER_STATUSES).join(',') } },
};

export const policiesBaseFilterConfig = {
  f: {
    status: {
      in: Object.keys(PolicyStatus)
        .filter(
          (status) =>
            !(
              status in
              { BOUND_STATUS, CANCEL_TRANSACTION, VOID_TRANSACTION_STATUS }
            )
        )
        .map(formatPolicyStatus)
        .join(','),
    },
  },
};

const accountsQueryDefaults = { size: 20, orderBy: 'name', order: 'asc' };
const submissionsQueryDefaults = {
  size: 20,
  orderBy: 'modified',
  order: 'desc',
};
const quotesQueryDefaults = {
  size: 20,
  orderBy: 'modified',
  order: 'desc',
};
const bindersQueryDefaults = {
  size: 20,
  orderBy: 'effectiveDate',
  order: 'asc',
  ...bindersBaseFilterConfig,
};
const policiesQueryDefaults = {
  size: 20,
  orderBy: 'endDate',
  order: 'asc',
  ...policiesBaseFilterConfig,
};

export const agencyDashboardQueryDefaults: AgencyDashboardQueries = {
  ACCOUNTS_QUERY: accountsQueryDefaults,
  QUOTES_QUERY: quotesQueryDefaults,
  BINDERS_QUERY: bindersQueryDefaults,
  POLICIES_QUERY: policiesQueryDefaults,
  SUBMISSIONS_QUERY: submissionsQueryDefaults,
};

// filter statics
export interface FilterOptionConfig {
  id: string;
  label: string;
  f: any;
}

export const initialFilters = {
  [AGENCY_DASHBOARD_LISTING_TABS.QUOTES]: {},
  [AGENCY_DASHBOARD_LISTING_TABS.BINDERS]: {},
  [AGENCY_DASHBOARD_LISTING_TABS.POLICIES]: {},
  [AGENCY_DASHBOARD_LISTING_TABS.ACCOUNTS]: {},
  [AGENCY_DASHBOARD_LISTING_TABS.SUBMISSIONS]: {},
};

const generateDefaultStatusFilterConfig = (
  status: string,
  isQuote?: boolean
) => {
  return isQuote
    ? { id: status, label: status, f: { agencyStatus: { in: status } } }
    : { id: status, label: status, f: { status: { in: status } } };
};

export const filterStatics = {
  [AGENCY_DASHBOARD_LISTING_TABS.QUOTES]: [
    {
      groupName: 'Type',
      options: [
        {
          id: 'NEW BUSINESS',
          label: 'NEW BUSINESS',
          f: { isRenewal: { bo: 0 } },
        },
        { id: 'RENEWALS', label: 'RENEWALS', f: { isRenewal: { bo: 1 } } },
      ],
    },
    {
      groupName: 'Status',
      options: [
        generateDefaultStatusFilterConfig(READY_STATUS, true),
        generateDefaultStatusFilterConfig(REFERRED_STATUS, true),
        generateDefaultStatusFilterConfig(EXPIRED_STATUS, true),
        {
          id: 'INACTIVE',
          label: 'INACTIVE',
          f: { agencyStatus: { in: [INVALID_STATUS, DECLINED_STATUS] } },
        },
      ],
    },
  ],
  [AGENCY_DASHBOARD_LISTING_TABS.BINDERS]: [
    {
      groupName: 'Payment',
      options: [
        { id: 'PAID', label: 'PAID', f: { isPaid: { bo: 1 } } },
        { id: 'NEED PAYMENT', label: 'NEED PAYMENT', f: { isPaid: { bo: 0 } } },
      ],
    },
    {
      groupName: 'Status',
      options: [
        generateDefaultStatusFilterConfig(BOUND_STATUS),
        {
          id: 'RESCINDED',
          label: 'RESCINDED',
          f: { status: { in: BINDER_RESCINDED } },
        },
        {
          id: 'BINDER EXPIRED',
          label: 'BINDER EXPIRED',
          f: { status: { in: 'BINDER_EXPIRED' } },
        },
      ],
    },
  ],
  [AGENCY_DASHBOARD_LISTING_TABS.POLICIES]: [
    {
      groupName: 'Type',
      options: [
        {
          id: 'OPEN FOR RENEWAL',
          label: 'OPEN FOR RENEWAL',
          f: { isOpenForRenewal: { bo: 1 } },
        },
      ],
    },
    {
      groupName: 'Status',
      options: [
        generateDefaultStatusFilterConfig(EXPIRED_STATUS),
        generateDefaultStatusFilterConfig(RENEWED_STATUS),
        {
          id: 'CANCELLATIONS',
          label: 'CANCELLATIONS',
          f: {
            status: {
              in: [
                'CANCELLED_STATUS',
                'CANCELLED_PENDING_STATUS',
                'CANCELLED_IN_REINSTATEMENT_PERIOD',
                'FLAT_CANCELLED_STATUS',
              ].map(formatPolicyStatus),
            },
          },
        },
        {
          id: 'INACTIVE',
          label: 'INACTIVE',
          f: {
            status: { in: [DELETED_STATUS, VOID_STATUS, LOST_STATUS] },
          },
        },
      ],
    },
  ],
  [AGENCY_DASHBOARD_LISTING_TABS.ACCOUNTS]: [
    {
      groupName: 'Type',
      options: [
        {
          id: 'ACTIVE POLICY',
          label: 'ACTIVE POLICY',
          f: { status: { in: 'Active' } },
        },
        {
          id: 'OPEN FOR RENEWAL',
          label: 'OPEN FOR RENEWAL',
          f: { isOpenForRenewal: { bo: 1 } },
        },
        {
          id: 'NOT QUOTED YET',
          label: 'NOT QUOTED YET',
          f: { status: { in: 'Open' } },
        },
        {
          id: 'POTENTIAL SUBMISSIONS',
          label: 'POTENTIAL SUBMISSIONS',
          f: {
            isRetreadable: { bo: 1 },
          },
        },
      ],
    },
  ],
  [AGENCY_DASHBOARD_LISTING_TABS.SUBMISSIONS]: [
    {
      groupName: 'Status',
      options: [
        {
          id: 'ACTIVE',
          label: 'ACTIVE',
          f: {
            submissionStatus: {
              in: ACTIVE_SUBMISSION_STATUSES,
            },
          },
        },
      ],
    },
  ],
};

export const mergeFilters = (filters: any) => {
  return {
    f: Object.values(filters).reduce((acc: any, filter: any) => {
      if (filter.agencyStatus) {
        return {
          ...acc,
          agencyStatus: {
            in: _.union(acc.agencyStatus?.in, [filter.agencyStatus.in]),
          },
        };
      }
      if (filter.status) {
        return {
          ...acc,
          status: { in: _.union(acc.status?.in, [filter.status.in]) },
        };
      }
      return { ...acc, ...filter };
    }, {}),
  };
};

// listing header config

export const quotesHeaderConfig = [
  { name: 'effectiveDate', label: 'Effective', sort: true, width: '13%' },
  { name: 'modified', label: 'Last Updated', sort: true, width: '13%' },
  { name: 'companyName', label: 'Client', sort: true, width: '28%' },
  { name: 'quoteNumber', label: 'Quote Number', sort: true, width: '18%' },
  {
    name: 'agencyStatus',
    label: 'Status',
    sort: true,
    width: '13%',
    style: { justifyContent: 'center' },
  },
  {
    name: 'actions',
    label: 'Actions',
    sort: false,
    width: '13%',
    divider: false,
    style: { marginRight: 0, justifyContent: 'center' },
  },
];

export const getPoliciesHeaderConfig = (isBinder: boolean) => [
  {
    name: isBinder ? 'effectiveDate' : 'endDate',
    label: isBinder ? 'Effective' : 'Expiration',
    sort: true,
    width: '15%',
  },
  { name: 'companyName', label: 'Client', sort: true, width: '30%' },
  {
    name: 'policyNumber',
    label: isBinder ? 'Binder Number' : 'Policy Number',
    sort: true,
    width: '25%',
  },
  {
    name: 'status',
    label: 'Status',
    sort: true,
    width: '15%',
    style: { justifyContent: 'center' },
  },
  {
    name: 'actions',
    label: 'Actions',
    sort: false,
    width: '15%',
    divider: false,
    style: { marginRight: 0, justifyContent: 'center' },
  },
];
